@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --my-variable: 150px; /* Define the variable */
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.homeNavbar {
  background-image: url("/public/images/home_navbar_bg.avif");
}

.footer_links_heading {
  margin-top: 32px;
  margin-bottom: 4px;
  list-style: none;
  /* font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif; */
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: rgba(255, 255, 255, 0.92);
}
.footer_links {
  margin-top: 16px;
  list-style: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.3px;
  color: rgba(255, 255, 255, 0.6);
}

.productListNav_heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.4px;
  color: rgba(2, 6, 12, 0.75);
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.productListNav_subheading {
  font-weight: 200;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: -0.33px;
  color: rgba(2, 6, 12, 0.6);
}

.category-sidebar::before {
  content: "";
  position: absolute;
  transition: transform 300ms ease-out 0s;
  top: 0px;
  transform: translateY(calc(var(--my-variable)));
  left: 0px;
  width: 4px;
  height: 48px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: rgb(158, 30, 98);
}

.category-sidebar li:first-child {
  margin-top: 12px;
}

.rs::before {
  content: "\20B9";
}
.-rs:before {
  content: "-\20B9";
}

._3z4E2 {
  min-height: 38px;
  min-width: 38px;
  height: 38px;
  width: 38px;
  border-radius: 8px;
  border: 1px solid rgba(2, 6, 12, 0.15);
  overflow: hidden;
  background: #fff;
  transition: margin-left 0.3s ease-in-out;
}

.ModalOpen {
  animation: openModal 0.2s ease-out forwards;
}
.ModalClosed {
  animation: closeModal 0.2s ease-out forwards;
}

.swiper-button-next-custom,
.swiper-button-prev-custom {
  /* Adjust as needed */
}

.swiper-button-prev-custom {
  left: 10px;
  /* Adjust as needed */
}

.swiper-button-next-custom {
  right: 10px;
  /* Adjust as needed */
}

@keyframes openModal {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes closeModal {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"), url(./fonts/Gilroy-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy-Semibold";
  src: local("Gilroy-Semibold"),
    url(./fonts/Gilroy-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"), url(./fonts/Gilroy-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"), url(./fonts/Gilroy-Light.ttf) format("truetype");
}

/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Light.woff2") format("woff2"),
    url("./fonts/Satoshi-Light.woff") format("woff"),
    url("./fonts/Satoshi-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-LightItalic.woff2") format("woff2"),
    url("./fonts/Satoshi-LightItalic.woff") format("woff"),
    url("./fonts/Satoshi-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Regular.woff2") format("woff2"),
    url("./fonts/Satoshi-Regular.woff") format("woff"),
    url("./fonts/Satoshi-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Italic.woff2") format("woff2"),
    url("./fonts/Satoshi-Italic.woff") format("woff"),
    url("./fonts/Satoshi-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Medium.woff2") format("woff2"),
    url("./fonts/Satoshi-Medium.woff") format("woff"),
    url("./fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-MediumItalic.woff2") format("woff2"),
    url("./fonts/Satoshi-MediumItalic.woff") format("woff"),
    url("./fonts/Satoshi-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Bold.woff2") format("woff2"),
    url("./fonts/Satoshi-Bold.woff") format("woff"),
    url("./fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-BoldItalic.woff2") format("woff2"),
    url("./fonts/Satoshi-BoldItalic.woff") format("woff"),
    url("./fonts/Satoshi-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Black.woff2") format("woff2"),
    url("./fonts/Satoshi-Black.woff") format("woff"),
    url("./fonts/Satoshi-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-BlackItalic.woff2") format("woff2"),
    url("./fonts/Satoshi-BlackItalic.woff") format("woff"),
    url("./fonts/Satoshi-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

.subheading {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  color: rgba(2, 6, 12, 0.75);
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ed1c24;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
